import { fontFamilyFallback } from '../jsonTokens/util';

const fontWeight = {
  primary: {
    heavy: { value: '700' },
    semi: { value: '600' },
    medium: { value: '500' },
    reg: { value: '400' },
  },
  secondary: {
    reg: { value: '400' },
  },
};

const size = {
  xs: {
    '01': { value: '0.125' },
    '02': { value: '0.25' },
    '03': { value: '0.375' },
    '04': { value: '0.425' },
  },
  sm: {
    '01': { value: '0.5' },
    '02': { value: '0.625' },
    '03': { value: '0.75' },
    '04': { value: '0.875' },
  },
  md: {
    '01': { value: '1' },
    '02': { value: '1.125' },
    '03': { value: '1.25' },
    '04': { value: '1.375' },
  },
  lg: {
    '01': { value: '1.5' },
    '02': { value: '1.75' },
    '03': { value: '2' },
    '04': { value: '2.25' },
  },
  xl: {
    '01': { value: '2.50' },
    '02': { value: '3' },
    '03': { value: '3.375' },
    '04': { value: '3.75' },
  },
  xxl: {
    '01': { value: '4.25' },
    '02': { value: '4.75' },
    '03': { value: '5.25' },
    '04': { value: '6' },
  },
};

const fontFamily = {
  primary: { value: 'Inter' },
  secondary: { value: 'Libre Baskerville' },
};

export const primaryFont = `${fontFamily['primary'].value}, ${fontFamilyFallback.primary}`;
export const secondaryFont = `${fontFamily['secondary'].value}, ${fontFamilyFallback.secondary}`;
export interface MidwestTypographyToken {
  font: string;
  rem: string;
  weight: string;
  lineHeight: number;
  textTransform?: string;
}
export const textBodyHeavyDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['md']['01'].value,
  lineHeight: 22,
};
export const textBodyHeavySm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['sm']['04'].value,
  lineHeight: 20,
};
export const textBodyHeavyXs: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['sm']['03'].value,
  lineHeight: 18,
};
export const textBodyMediumDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['md']['01'].value,
  lineHeight: 22,
};
export const textBodyMediumSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['sm']['04'].value,
  lineHeight: 20,
};
export const textBodyMediumXs: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['sm']['03'].value,
  lineHeight: 18,
};
export const textBodyRegDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['md']['01'].value,
  lineHeight: 22,
};
export const textBodyRegSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['04'].value,
  lineHeight: 20,
};
export const textBodyRegXs: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['03'].value,
  lineHeight: 18,
};
export const textMiniDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['02'].value,
  lineHeight: 14,
};
export const textMiniDefaultLink: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['02'].value,
  lineHeight: 14,
};
export const textRegDefaultLink: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['md']['01'].value,
  lineHeight: 22,
};
export const textRegSmLink: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['04'].value,
  lineHeight: 20,
};
export const textRegXsLink: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['03'].value,
  lineHeight: 18,
};
export const textHeroDesktopXl: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['xl']['03'].value,
  lineHeight: 72,
};
export const textHeroDesktopLg: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['xl']['01'].value,
  lineHeight: 52,
};
export const textHeroDesktopMd: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['lg']['03'].value,
  lineHeight: 44,
};
export const textHeroDesktopSm: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['lg']['01'].value,
  lineHeight: 32,
};
export const textHeroMobileXl: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['xl']['01'].value,
  lineHeight: 56,
};
export const textHeroMobileLg: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['lg']['04'].value,
  lineHeight: 48,
};
export const textHeroMobileMd: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['lg']['02'].value,
  lineHeight: 36,
};
export const textHeroMobileSm: MidwestTypographyToken = {
  font: secondaryFont,
  weight: fontWeight['secondary']['reg'].value,
  rem: size['md']['03'].value,
  lineHeight: 28,
};
export const textTitleDesktopXl: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['xl']['01'].value,
  lineHeight: 52,
};
export const textTitleDesktopLg: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['lg']['03'].value,
  lineHeight: 42,
};
export const textTitleDesktopMd: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['lg']['01'].value,
  lineHeight: 32,
};
export const textTitleDesktopSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['md']['03'].value,
  lineHeight: 28,
};
export const textTitleMobileXl: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['lg']['04'].value,
  lineHeight: 46,
};
export const textTitleMobileLg: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['lg']['02'].value,
  lineHeight: 36,
};
export const textTitleMobileMd: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['md']['04'].value,
  lineHeight: 28,
};
export const textTitleMobileSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['md']['02'].value,
  lineHeight: 24,
};
export const textSubTitleDesktopMd: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['md']['03'].value,
  lineHeight: 28,
};
export const textSubTitleMobileMd: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['md']['02'].value,
  lineHeight: 26,
};
export const textDataDesktopLg: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['xl']['02'].value,
  lineHeight: 64,
};
export const textDataDesktopMd: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['lg']['04'].value,
  lineHeight: 46,
};
export const textDataDesktopSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['lg']['01'].value,
  lineHeight: 32,
};
export const textDataMobileLg: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['lg']['04'].value,
  lineHeight: 46,
};
export const textDataMobileMd: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['lg']['02'].value,
  lineHeight: 36,
};
export const textDataMobileSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['md']['04'].value,
  lineHeight: 28,
};
export const textInterfaceHeavyDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['md']['01'].value,
  lineHeight: 20,
};
export const textInterfaceHeavySmall: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['sm']['04'].value,
  lineHeight: 16,
};
export const textInterfaceHeavyXs: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['heavy'].value,
  rem: size['sm']['03'].value,
  lineHeight: 16,
};
export const textInterfaceSemiDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['semi'].value,
  rem: size['md']['01'].value,
  lineHeight: 16,
};
export const textInterfaceSemiSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['semi'].value,
  rem: size['sm']['04'].value,
  lineHeight: 16,
};
export const textInterfaceSemiXs: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['semi'].value,
  rem: size['sm']['03'].value,
  lineHeight: 16,
};
export const textInterfaceMediumDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['md']['01'].value,
  lineHeight: 16,
};
export const textInterfaceMediumSmall: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['sm']['04'].value,
  lineHeight: 16,
};
export const textInterfaceMediumXs: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['sm']['03'].value,
  lineHeight: 16,
};

export const textInterfaceRegularDefault: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['md']['01'].value,
  lineHeight: 16,
};

export const textInterfaceRegularSm: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['04'].value,
  lineHeight: 16,
};

export const textInterfaceRegularXs: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['reg'].value,
  rem: size['sm']['03'].value,
  lineHeight: 16,
};

export const componentTableTextHeading: MidwestTypographyToken = {
  font: primaryFont,
  weight: fontWeight['primary']['medium'].value,
  rem: size['sm']['03'].value,
  lineHeight: 16,
  textTransform: 'capitalize',
};
