'use client';

import {
  BaseLinkStyles,
  ChevronRightIcon,
  IconWrapper,
  LinkOutIcon,
  LinkStyles,
  LinkWrapper,
} from './linkStyles';
import { BaseLinkProps, LinkProps } from './types';
import { MDSText, useMdsConfig } from '@midwest/web/base';
import { forwardRef } from 'react';
import styled from 'styled-components';

const getTextSize = (
  size: string,
  as: LinkProps['as'],
  children: React.ReactNode,
) => {
  switch (size) {
    case 'large':
      return (
        <MDSText.BodyMediumDefault as={as}>
          {children}
        </MDSText.BodyMediumDefault>
      );
    case 'medium':
      return <MDSText.BodyMediumSm as={as}>{children}</MDSText.BodyMediumSm>;
    case 'small':
      return <MDSText.BodyMediumXs as={as}>{children}</MDSText.BodyMediumXs>;
    default:
      return (
        <MDSText.BodyMediumDefault as={as}>
          {children}
        </MDSText.BodyMediumDefault>
      );
  }
};

const BaseLink = styled.a.attrs((props: BaseLinkProps) => ({
  as: props.$linkComp ? props.$linkComp : 'a',
  to: props.href,
}))<BaseLinkProps>`
  ${BaseLinkStyles}
`;

/**
 * @deprecated This component is deprecated and will be removed in a future release.
 * Please use the `MDSLink` component from `@midwest/web/link` instead.
 */
export const Link = styled(BaseLink)<LinkProps>`
  ${LinkStyles}
`;

/**
 * Interactive elements that allow users to navigate to a new page or website or jump to a section of the current page.
 */
export const MDSLink = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href,
      children,
      type,
      disabled,
      size = 'medium',
      onClick,
      as,
      darkBg = false,
      ...rest
    }: LinkProps,
    ref,
  ) => {
    const { linkComp } = useMdsConfig();

    return (
      // wrap Link to avoid style conflict between cursor: not-allowed and pointer-events: none
      <LinkWrapper
        data-testid="link-wrapper"
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Link
          onClick={onClick}
          disabled={disabled}
          $disabled={disabled}
          href={href}
          ref={ref}
          $linkComp={linkComp}
          $darkBg={darkBg}
          {...rest}
        >
          {getTextSize(size, as, children)}
          {type === 'navigational' || type === 'externalLink' ? (
            <IconWrapper>
              {type === 'navigational' && (
                <ChevronRightIcon
                  $disabled={disabled}
                  data-testid="chevron-right"
                  $size={size}
                  alt-text="chevron-right"
                />
              )}
              {type === 'externalLink' && (
                <LinkOutIcon
                  $disabled={disabled}
                  data-testid="link-out"
                  $size={size}
                  alt-text="arrow-diagonal"
                />
              )}
            </IconWrapper>
          ) : null}
        </Link>
      </LinkWrapper>
    );
  },
);

MDSLink.displayName = 'MDSLink';
