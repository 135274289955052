import * as tokens from './tokens.generated';

export type RadiusTypes = {
  radiusSmall: string;
  radiusMedium: string;
  radiusLarge: string;
  radiusFull: string;
  buttonRadius: string;
  formRadius: string;
  containerRadius: string;
  chipRadiusDefault: string;
  chipRadiusFocus: string;
};

export const radius: RadiusTypes = {
  radiusSmall: `${tokens.baseBorderRadiusSm}px`,
  radiusMedium: `${tokens.baseBorderRadiusMd}px`,
  radiusLarge: `${tokens.baseBorderRadiusLg}px`,
  radiusFull: `${tokens.baseBorderRadiusFull}px`,
  buttonRadius: `${tokens.componentButtonRadiusDefault}px`,
  formRadius: `${tokens.componentFormRadiusDefault}px`,
  containerRadius: `${tokens.componentContainerRadiusDefault}px`,
  chipRadiusDefault: `${tokens.baseBorderRadiusSm}px`,
  chipRadiusFocus: `${tokens.baseBorderRadiusMd}px`,
} as const;
