'use client';

import {
  ButtonContent,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
} from '../Button.styles';
import { renderIconsAndText } from '../buttonHelpers';
import { AnchorButtonProps, AnchorVariationProps } from '../types';
import { forwardRef } from 'react';
import styled from 'styled-components';

const BaseAnchorStyles = styled.a<AnchorButtonProps>`
  && {
    ${(p) => (!p.fullWidth ? 'display: inline-block;' : '')}
    text-decoration: none;
  }
`;

const PrimaryButton = styled(BaseAnchorStyles)`
  ${PrimaryButtonStyle}
`;

const SecondaryButton = styled(BaseAnchorStyles)`
  ${SecondaryButtonStyle}
`;

const anchorVariations = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
};

/**
 ```jsx
 import { MDSAnchorButton } from '@midwest/web/button';
 ```
 * Anchor tag styled to look like a button. For more documentation on variants/sizing see the [Button](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/thrivent-web-components-button--docs) component.
 */
export const MDSAnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  (
    {
      children,
      variant = 'primary',
      weight = 'strong',
      size = 'default',
      onClick,
      href,
      target = '_self',
      fullWidth = false,
      disabled = false,
      Icon,
      iconAlignment = 'left',
      iconOffset,
      mobileFullWidth = false,
      ...rest
    }: AnchorButtonProps,
    ref?: React.Ref<HTMLAnchorElement>,
  ) => {
    const AnchorVariation: React.ComponentType<AnchorVariationProps> =
      anchorVariations[variant];

    const handleDisabled = (
      e:
        | React.MouseEvent<HTMLAnchorElement, MouseEvent>
        | React.KeyboardEvent<HTMLAnchorElement>,
    ) => {
      if (e) {
        if ((e as React.KeyboardEvent<HTMLAnchorElement>).key === 'Tab') {
          return;
        }
        if (disabled) {
          e.preventDefault();
          e.stopPropagation();
        }
        !disabled &&
          onClick &&
          onClick(e as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
      }
    };

    return (
      <AnchorVariation
        tabIndex={disabled ? -1 : 0}
        target={target}
        onClick={handleDisabled}
        variant={variant}
        weight={weight}
        size={size}
        href={disabled ? '' : href}
        fullWidth={fullWidth}
        ref={ref}
        disabled={disabled}
        mobileFullWidth={mobileFullWidth}
        {...rest}
      >
        <ButtonContent $loading={false}>
          {renderIconsAndText(iconAlignment, children, Icon, true, iconOffset)}
        </ButtonContent>
      </AnchorVariation>
    );
  },
);

MDSAnchorButton.displayName = 'MDSAnchorButton';
