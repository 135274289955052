type TokenValue = {
  value: string;
};

type TokenMap = Record<string, TokenValue>;

type MidwestSystemTokenMap = {
  size: Record<string, TokenMap>;
  fontFamily: TokenMap;
  fontWeight: Record<string, TokenMap>;
  lineHeight: TokenMap;
};

export const system: MidwestSystemTokenMap = {
  size: {
    xs: {
      '01': { value: '0.125' },
      '02': { value: '0.25' },
      '03': { value: '0.375' },
      '04': { value: '0.425' },
    },
    sm: {
      '01': { value: '0.5' },
      '02': { value: '0.625' },
      '03': { value: '0.75' },
      '04': { value: '0.875' },
    },
    md: {
      '01': { value: '1' },
      '02': { value: '1.125' },
      '03': { value: '1.25' },
      '04': { value: '1.375' },
    },
    lg: {
      '01': { value: '1.5' },
      '02': { value: '1.75' },
      '03': { value: '2' },
      '04': { value: '2.25' },
    },
    xl: {
      '01': { value: '2.50' },
      '02': { value: '3' },
      '03': { value: '3.375' },
      '04': { value: '3.75' },
    },
    xxl: {
      '01': { value: '4.25' },
      '02': { value: '4.75' },
      '03': { value: '5.25' },
      '04': { value: '6' },
    },
  },
  fontFamily: {
    primary: { value: 'Inter' },
    secondary: { value: 'Libre Baskerville' },
  },
  fontWeight: {
    primary: {
      heavy: { value: '700' },
      semi: { value: '600' },
      medium: { value: '500' },
      reg: { value: '400' },
    },
    secondary: {
      reg: { value: '400' },
    },
  },
  lineHeight: {
    xxs: { value: '1' },
    xs: { value: '1.30' },
    sm: { value: '1.35' },
    md: { value: '1.45' },
    lg: { value: '1.62' },
    xl: { value: '1.75' },
  },
};
