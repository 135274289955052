'use client';

import { forwardRef } from 'react';
import styled from 'styled-components';

const Anchor = styled.a`
  background-color: ${(p) => p.theme.midwestColors.neutralDarkStrong};
  border-radius: 0.25rem;
  color: ${(p) => p.theme.midwestColors.neutralLightWeak};
  font-size: 0.875rem;
  font-weight: 630;
  left: 0.5rem;
  line-height: 1.25rem;
  opacity: 1;
  padding-block: 0.5rem;
  padding-inline: 0.75rem;
  position: fixed;
  top: 0.5rem;
  translate: 0 -3rem;
  transition-duration: 0.15s;
  transition-property: translate;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 50;
  &:hover,
  &:focus {
    text-decoration: none;
    translate: 0 0;
  }
`;

export interface LandmarkLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  /** Sets the content. **Required** */
  children: React.ReactNode;
  /** Sets the `href` attribute. **Required** */
  href: string;
  /** Sets the `id` attribute. */
  id: string;
}

export const LandmarkLink = forwardRef<HTMLAnchorElement, LandmarkLinkProps>(
  ({ children, href, id, ...props }, forwardedRef) => {
    return (
      <Anchor {...props} id={id} href={href} ref={forwardedRef}>
        {children}
      </Anchor>
    );
  },
);
