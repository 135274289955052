import '../buttonHelpers';
import { ButtonVariationProps } from '../types';
import { getBackgroundColor } from './backgroundColors';
import { MidwestColors } from '@thrivent/midwest-shared';
import { css } from 'styled-components';

export const secondaryStrongStyles = css<ButtonVariationProps>`
  outline: ${(p) => p.theme.border.widthDefault} solid
    ${(p) => p.theme.midwestColors.componentButtonColorBorderSecondaryDefault};
  background-color: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors).secondaryStrongBg};
  color: ${(p) =>
    p.theme.midwestColors.componentButtonColorTextStrongSecondary};
  ${(p) =>
    !p.disabled
      ? css`
          &:focus-visible {
            background-color: ${(p) =>
              p.theme.midwestColors
                .componentButtonColorBackgroundSubtleSecondaryHover};
          }
          &:hover {
            background-color: ${(p) =>
              p.theme.midwestColors
                .componentButtonColorBackgroundStrongSecondaryHover};
          }
          &:active {
            background-color: ${(p) =>
              p.theme.midwestColors
                .componentButtonColorBackgroundStrongSecondaryPressed};
          }
        `
      : css`
          outline-color: transparent;
        `}
`;

export const secondarySubtleStyles = css<ButtonVariationProps>`
  background: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors).secondarySubtleBg};
  outline: ${(p) => p.theme.border.widthDefault} solid
    ${(p) =>
      p.theme.midwestColors.componentButtonColorBorderSubtleSecondaryDefault};
  color: ${(p) =>
    p.theme.midwestColors.componentButtonColorTextSubtleSecondary};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtleSecondaryHover};
      }
      &:hover {
        outline-color: ${(p) =>
          p.theme.midwestColors.componentButtonColorBorderSubtleSecondaryHover};
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtleSecondaryHover};
      }
      &:active {
        outline-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBorderSubtleSecondaryPressed};
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtleSecondaryPressed};
      }
    `}
`;
