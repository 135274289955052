import { MidwestColors } from '@thrivent/midwest-shared';

// These colors are defined here to be used for the loading spinner overlay as well as the button background colors

export const getBackgroundColor = (colors: MidwestColors) => {
  return {
    primaryStrongBg: colors.componentButtonColorBackgroundStrongPrimaryDefault,
    primarySubtleBg: colors.componentButtonColorBackgroundSubtleDefault,
    primaryGhostBg: 'transparent',
    secondaryStrongBg:
      colors.componentButtonColorBackgroundStrongSecondaryDefault,
    secondarySubtleBg: colors.componentButtonColorBackgroundSubtleDefault,
    secondaryGhostBg: 'transparent',
    destructiveStrongBg:
      colors.componentButtonColorBackgroundStrongDestructiveDefault,
    destructiveSubtleBg: colors.componentButtonColorBackgroundSubtleDefault,
    destructiveGhostBg: 'transparent',
  };
};
