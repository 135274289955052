import * as tokens from './tokens.generated';

export type BorderTypes = {
  widthDefault: string;
  widthEmphasised: string;
  widthFocused: string;
};

export const border: BorderTypes = {
  widthDefault: `${tokens.componentContainerBorderWidthDefault}px`,
  widthEmphasised: `${tokens.componentContainerBorderWidthEmphasis}px`,
  widthFocused: `${tokens.componentContainerBorderWidthFocus}px`,
} as const;
