import { ButtonIconContainer } from './Button.styles';
import { FC, ReactNode, SVGProps } from 'react';

export const renderButtonIcon = (
  Icon: FC<SVGProps<SVGSVGElement>>,
  large: boolean,
  iconOnly = false,
  anchor = false,
  offset?: number,
) => {
  return (
    <ButtonIconContainer
      $large={large}
      iconOnly={iconOnly}
      $anchor={anchor}
      $offset={offset}
    >
      <Icon role="presentation" />
    </ButtonIconContainer>
  );
};

export const renderIconsAndText = (
  iconAlignment: 'left' | 'right',
  children: ReactNode,
  Icon?: FC<SVGProps<SVGSVGElement>>,
  anchor = false,
  offset?: number,
) => {
  return (
    <>
      {Icon &&
        iconAlignment === 'left' &&
        renderButtonIcon(Icon, false, false, anchor, offset)}
      {children}
      {Icon &&
        iconAlignment === 'right' &&
        renderButtonIcon(Icon, false, false, anchor, offset)}
    </>
  );
};
