import { ButtonVariationProps } from '../types';
import { getBackgroundColor } from './backgroundColors';
import { MidwestColors } from '@thrivent/midwest-shared';
import { css } from 'styled-components';

export const strongDestructiveStyles = css<ButtonVariationProps>`
  background: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors)
      .destructiveStrongBg};
  color: ${(p) => p.theme.midwestColors.textPrimaryDarkBG};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongDestructiveHover};
      }
      &:hover {
        background: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongDestructiveHover};
      }
      &:active {
        background: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongDestructivePressed};
      }
    `}
`;

export const subtleDestructiveStyles = css<ButtonVariationProps>`
  outline: ${(p) => p.theme.border.widthDefault} solid
    ${(p) =>
      p.theme.midwestColors.componentButtonColorBorderSubtleDestructiveDefault};
  background: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors)
      .destructiveSubtleBg};
  color: ${(p) =>
    p.theme.midwestColors.componentButtonColorTextSubtleDestructive};
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        outline-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBorderSubtleDestructiveHover};
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtleDestructiveHover};
      }
      &:active {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtleDestructivePressed};
        outline-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBorderSubtleDestructivePressed};
      }
      &:focus-visible {
        background-color: ${(p) => p.theme.midwestColors.backgroundPrimary};
      }
    `}
`;

export const ghostDestructiveStyles = css<ButtonVariationProps>`
  outline: ${(p) => p.theme.border.widthDefault} solid transparent;
  background: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors)
      .destructiveGhostBg};
  color: ${(p) =>
    p.theme.midwestColors.componentButtonColorTextGhostDestructive};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background: ${({ theme }) =>
          getBackgroundColor(theme.midwestColors as MidwestColors)
            .destructiveGhostBg};
      }
      &:hover {
        background: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtleDestructiveHover};
        outline-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBorderSubtleDestructiveHover};
      }
      &:active {
        background: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtleDestructivePressed};
        outline-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBorderSubtleDestructivePressed};
      }
    `}
`;
