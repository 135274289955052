import {
  ghostDestructiveStyles,
  strongDestructiveStyles,
  subtleDestructiveStyles,
} from './buttonStyles/destructiveButtonStyles';
import {
  primarySecondaryGhostStyles,
  primaryStrongStyles,
  primarySubtleStyles,
} from './buttonStyles/primaryButtonStyles';
import {
  secondaryStrongStyles,
  secondarySubtleStyles,
} from './buttonStyles/secondaryButtonStyles';
import { ButtonVariationProps } from './types';
import {
  primaryFont,
  tablet,
  textInterfaceMediumDefault,
  textInterfaceMediumSmall,
  textInterfaceSemiDefault,
  textInterfaceSemiSm,
} from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

const iconOnlyPadding = css<ButtonVariationProps>`
  padding: ${(p) =>
    p.size === 'small' ? p.theme.spacing.xsm : p.theme.spacing.sm};
`;

const defaultPadding = css<ButtonVariationProps>`
  padding: ${(p) =>
    p.size === 'small'
      ? p.theme.spacing.sm
      : `${p.theme.spacing.md} ${p.theme.spacing.xlg}`};
`;

export const BaseButtonStyles = css<ButtonVariationProps>`
  transition: all 0.2s ease-in-out 0s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: ${(p) => p.theme.spacing.xsm};
  border-radius: ${(p) => p.theme.radius.buttonRadius};
  border: none;
  font-family: ${primaryFont};
  font-weight: ${textInterfaceSemiDefault.weight};
  line-height: ${textInterfaceSemiDefault.lineHeight}px;
  font-size: ${textInterfaceSemiDefault.rem}rem;
  text-align: center;
  ${(p) => (p.iconOnly ? iconOnlyPadding : defaultPadding)}
  outline: none;
  position: relative;

  width: ${(p) => (p.fullWidth || p.mobileFullWidth ? '100%' : 'fit-content')};
  ${tablet} {
    width: ${(p) => (p.fullWidth ? '100%' : 'fit-content')};
  }

  & svg {
    fill: currentColor;
  }

  ${(p) =>
    !p.iconOnly &&
    css`
      min-width: ${p.size === 'small' ? '80px' : '120px'};
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;
    `} 
    &:focus-visible {
    &::after {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: ${(p) => p.theme.border.widthFocused} solid
        ${(p) =>
          p.$darkBg
            ? p.theme.midwestColors.borderFocusDarkBg
            : p.theme.midwestColors.borderFocus};
      border-radius: 10px;
    }
  }
`;

export const PrimaryButtonStyle = css<ButtonVariationProps>`
  ${BaseButtonStyles}
  font-weight: ${textInterfaceMediumDefault.weight};
  line-height: ${textInterfaceMediumDefault.lineHeight}px;
  font-size: ${textInterfaceMediumDefault.rem}rem;

  ${(p) =>
    p.size === 'small' &&
    css`
      line-height: ${textInterfaceMediumSmall.lineHeight}px;
      font-size: ${textInterfaceMediumSmall.rem}rem;
      font-weight: ${textInterfaceMediumSmall.weight};
    `};

  ${(p) => p.weight === 'strong' && primaryStrongStyles}
  ${(p) => p.weight === 'subtle' && primarySubtleStyles}
  ${(p) => p.weight === 'ghost' && primarySecondaryGhostStyles}
`;

export const SecondaryButtonStyle = css<ButtonVariationProps>`
  ${BaseButtonStyles}
  font-weight: ${textInterfaceMediumDefault.weight};
  line-height: ${textInterfaceMediumDefault.lineHeight}px;
  font-size: ${textInterfaceMediumDefault.rem}rem;

  ${(p) =>
    p.size === 'small' &&
    css`
      line-height: ${textInterfaceMediumSmall.lineHeight}px;
      font-size: ${textInterfaceMediumSmall.rem}rem;
      font-weight: ${textInterfaceMediumSmall.weight};
    `};
  ${(p) => p.weight === 'strong' && secondaryStrongStyles}
  ${(p) => p.weight === 'subtle' && secondarySubtleStyles}
  ${(p) => p.weight === 'ghost' && primarySecondaryGhostStyles}
`;

export const DestructiveButtonStyle = css<ButtonVariationProps>`
  ${BaseButtonStyles}
  font-weight: ${textInterfaceSemiDefault.weight};
  line-height: ${textInterfaceSemiDefault.lineHeight}px;
  font-size: ${textInterfaceSemiDefault.rem}rem;
  ${(p) =>
    p.size === 'small' &&
    css`
      line-height: ${textInterfaceSemiSm.lineHeight}px;
      font-size: ${textInterfaceSemiSm.rem}rem;
      font-weight: ${textInterfaceSemiSm.weight};
    `};
  ${(p) => p.weight === 'strong' && strongDestructiveStyles}
  ${(p) => p.weight === 'subtle' && subtleDestructiveStyles}
  ${(p) => p.weight === 'ghost' && ghostDestructiveStyles}
`;

export const ButtonIconContainer = styled.div<{
  $large?: boolean;
  iconOnly?: boolean;
  $anchor?: boolean;
  $offset?: number;
}>`
  position: relative;
  top: ${(p) => p.$offset ?? '0'}px;
  height: ${(p) =>
    p.$large ? `${p.theme.spacing.xlg}` : `${p.theme.spacing.md}`};
  width: ${(p) =>
    p.$large ? `${p.theme.spacing.xlg}` : `${p.theme.spacing.md}`};
`;

ButtonIconContainer.defaultProps = {
  $large: false,
};

export const ButtonContent = styled.div<{ $loading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => p.theme.spacing.xsm};
  flex-direction: row;
  color: ${(p) => p.$loading && 'transparent'};
`;
