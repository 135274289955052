import { ThemeProvider } from '../ThemeProvider';
import { showCreditUnionModalAtom } from './CreditUnionModal.atom';
import CreditUnionModalStyles from './CreditUnionModal.style';
import {
  CloseButton,
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@thrivent-web/ui/atoms';
import { useAtom } from 'jotai';

const CreditUnionModal = () => {
  const { Title, Body, Content, FooterButton } = CreditUnionModalStyles;
  const [open, setOpen] = useAtom(showCreditUnionModalAtom);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider>
      <Dialog
        open={!!open}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            closeModal();
          }
        }}
      >
        <Content onEscapeKeyDown={closeModal} size="large" zIndex={598}>
          <DialogHeader>
            <DialogTitle>
              <Title as="div">Leaving Thrivent</Title>
            </DialogTitle>
            <CloseButton onClick={closeModal} />
          </DialogHeader>
          <Body>
            <div>
              You are now leaving the Thrivent website. Deposit and lending
              services are offered by Thrivent Credit Union, the marketing name
              for Thrivent Federal Credit Union, a member-owned not-for-profit
              financial cooperative that is federally insured by the National
              Credit Union Administration and doing business in accordance with
              the Federal Fair Lending Laws. Insurance, securities, investment
              advisory and trust and investment management accounts and services
              offered by Thrivent, the marketing name for Thrivent Financial for
              Lutherans, or its affiliates are not deposits or obligations of
              Thrivent Federal Credit Union, are not guaranteed by Thrivent
              Federal Credit Union or any bank, are not insured by the NCUA,
              FDIC or any other federal government agency, and involve
              investment risk, including possible loss of the principal amount
              invested.
            </div>
            <div>Must qualify for membership in TCU.</div>
            <div>
              Any data or personal information collected by websites other than
              Thrivent is not covered by Thrivent&apos;s privacy policy. We
              recommend you read the privacy policies of those sites as they may
              be different from Thrivent&apos;s policy.
            </div>
          </Body>
          <DialogFooter>
            <FooterButton
              href={
                typeof open === 'string' ? open : 'https://www.thriventcu.com/'
              }
            >
              Visit Thrivent Credit Union
            </FooterButton>
          </DialogFooter>
        </Content>
      </Dialog>
    </ThemeProvider>
  );
};

export default CreditUnionModal;
