import { GlobalStyleOverride } from './GlobalStyleOverride';
import { GlobalStyle, theme } from '@midwest/web/theme';
import { Provider, WritableAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

type JotaiInitialValues = Array<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [WritableAtom<unknown, any[], unknown>, unknown]
>;

export interface ThemeProviderProps {
  children: React.ReactNode;
  jotaiValues?: JotaiInitialValues;
}

function HydrateAtoms({
  children,
  initialValues,
}: {
  children?: ReactNode;
  initialValues: JotaiInitialValues;
}) {
  useHydrateAtoms(initialValues);
  return children;
}

export default function ThemeProvider({
  children,
  jotaiValues,
}: ThemeProviderProps) {
  return (
    <Provider>
      <HydrateAtoms initialValues={jotaiValues ?? []}>
        <StyledThemeProvider theme={theme}>
          <GlobalStyle />
          <GlobalStyleOverride />
          {children}
        </StyledThemeProvider>
      </HydrateAtoms>
    </Provider>
  );
}
