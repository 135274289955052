import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Close } from '@thrivent-web/ui/icons';
import { theme } from '@thrivent-web/ui/tokens';
import { tablet } from '@thrivent/midwest-shared';
import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';

/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export type Sizes = 'small' | 'normal' | 'large';
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export type DialogProps = {
  size?: Sizes;
  onInteractOutside?: () => void;
  onEscapeKeyDown?: () => void;
  children: React.ReactNode;
  zIndex?: number;
};

const Content = ({ children, size, zIndex, ...rest }: DialogProps) => {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay zIndex={zIndex} />
      <StyledContent size={size} zIndex={zIndex} {...rest}>
        {children}
      </StyledContent>
    </DialogPrimitive.Portal>
  );
};

const StyledOverlay = styled(DialogPrimitive.Overlay)<
  Pick<DialogProps, 'zIndex'>
>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${theme.midwestColors.neutralDarkHeavy};
  opacity: 0.5;
  z-index: ${(p) => p.zIndex || 1};
`;

const StyledContent = styled(DialogPrimitive.Content)<DialogProps>`
  border-radius: 0.375rem;
  background-color: ${theme.midwestColors.neutralLightWeak};
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: ${(p) => p.zIndex || 1};

  &:hover,
  &:focus {
    outline: 'none';
  }

  ${tablet} {
    overflow: auto;
    border-radius: 0.375rem;
    height: auto;
    width: ${(p) =>
      p.size === 'small' ? '25rem' : p.size === 'large' ? '50rem' : '40rem'};
  }
`;
const StyledTitle = styled(DialogPrimitive.Title)`
  display: flex;
  flex: 1;
  margin: 0;
  font-weight: 500;
  color: ${theme.midwestColors.textPrimary};
  font-family: ${theme.font.primary};
`;

const StyledDescription = styled(DialogPrimitive.Description)`
  margin: 0.625rem 0 1.25rem;
  color: ${theme.midwestColors.textPrimary};
  font-size: 1rem;
  line-height: 1.5;
`;

const StyledCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  width: 2.25rem;
  height: 2.25rem;
`;

const DialogCloseHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface CloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  icon?: ReactNode;
}
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ onClick, icon, ...rest }, ref) => {
    return (
      <StyledCloseButton
        ref={ref}
        aria-label="close"
        onClick={onClick}
        tabIndex={0}
        {...rest}
      >
        {icon || <Close />}
      </StyledCloseButton>
    );
  },
);
CloseButton.displayName = 'CloseButton';
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogClose = () => {
  return (
    <DialogCloseHeader>
      <DialogPrimitive.Close asChild>
        <StyledCloseButton>
          <Close />
        </StyledCloseButton>
      </DialogPrimitive.Close>
    </DialogCloseHeader>
  );
};

/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const Dialog = DialogPrimitive.Root;
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogContent = Content;
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogTitle = StyledTitle;
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogDescription = StyledDescription;
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogHeader = styled.div`
  display: flex;
  padding: 0.75rem;
  border: 1px solid ${theme.midwestColors.neutralLightHeavy};
  height: 3.75rem;
  ${tablet} {
    height: auto;
    padding: 1.5rem;
  }
`;
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogBody = styled.div<{ size?: Sizes; scrollContent?: boolean }>`
  display: flex;
  padding: 0 0.75rem;
  height: calc(100% - 8.75rem);
  overflow: scroll;

  ${tablet} {
    padding: 1.5rem;
    ${(p) =>
      p.scrollContent &&
      css`
        max-height: ${p.scrollContent && p.size === 'small'
          ? '15.125rem'
          : p.scrollContent && p.size === 'large'
            ? '20rem'
            : '15.875rem'};
        overflow: scroll;
      `}
  }
`;
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogFooter = styled.div`
  display: flex;
  padding: 0.75rem;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  right: 0;
  background-color: ${theme.midwestColors.neutralLightWeak};
  bottom: 0;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  height: 5rem;
  ${tablet} {
    position: relative;
    height: auto;
    padding: 1.5rem;
  }
`;
/**
 * @deprecated use a different component from the [Midwest Design Library](https://midwest-storybook.icweb.stage.aws.tfcloud.corp/?path=/docs/about-intro--docs) instead
 */
export const DialogTrigger = styled(DialogPrimitive.Trigger)`
  background: none;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.0625rem;
  color: ${theme.midwestColors.identityBrandPrimaryMedium};
  border: none;
`;
