import { MDSAnchorButton } from '@midwest/web/button';
import { DialogBody, DialogContent } from '@thrivent-web/ui/atoms';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

const CreditUnionModalStyles = {
  Title: styled(MDSText.HeroSm)``,
  Body: styled(DialogBody)`
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    padding-top: 1.5rem;
  `,
  Content: styled(DialogContent)``,
  FooterButton: styled(MDSAnchorButton)`
    && {
      display: inline-flex;
    }
  `,
};
export default CreditUnionModalStyles;
