import { getCookie, setCookie } from 'cookies-next';

export const LICENSE_PLATE_COOKIE_OPTIONS = {
  path: '/',
  domain: 'thrivent.com',
};

export const LICENSE_PLATE_COOKIE_NAME = 'licensePlate';

export const getLicensePlateCookie = (): string | undefined => {
  const licensePlate = getCookie(LICENSE_PLATE_COOKIE_NAME);

  if (typeof licensePlate !== 'string') {
    return undefined;
  }

  return licensePlate;
};

export const setLicensePlateCookie = (licensePlate: string, expires: Date) =>
  setCookie(LICENSE_PLATE_COOKIE_NAME, licensePlate, {
    ...LICENSE_PLATE_COOKIE_OPTIONS,
    expires,
  });
