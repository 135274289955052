import {
  border,
  brandColors,
  breakpoints,
  colors,
  darkColors,
  primaryFont,
  radius,
  secondaryFont,
  shadow,
  spacing,
} from '@thrivent/midwest-shared';
import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    font: {
      primary: string;
      secondary: string;
    };
    spacing: typeof spacing;
    shadow: typeof shadow;
    radius: typeof radius;
    breakpoints: typeof breakpoints;
    midwestColors: typeof colors;
    border: typeof border;
  }
}

export const theme: DefaultTheme = {
  font: {
    primary: primaryFont,
    secondary: secondaryFont,
  },
  spacing: spacing,
  shadow: shadow,
  radius: radius,
  breakpoints: breakpoints,
  midwestColors: colors,
  border: border,
};

export const inverseTheme: DefaultTheme = {
  font: {
    primary: primaryFont,
    secondary: secondaryFont,
  },
  spacing: spacing,
  shadow: shadow,
  radius: radius,
  breakpoints: breakpoints,
  midwestColors: darkColors,
  border: border,
};

export const brandTheme: DefaultTheme = {
  font: {
    primary: primaryFont,
    secondary: secondaryFont,
  },
  spacing: spacing,
  shadow: shadow,
  radius: radius,
  breakpoints: breakpoints,
  midwestColors: brandColors,
  border: border,
};

export type Theme = typeof theme;
export * from './globalStyles';
export * from './FontLoader';
