import { BaseProps } from './types';
import { ArrowDiagonal, ArrowRight, ValidationProps } from '@midwest/web/base';
import styled, { css } from 'styled-components';

const disabledEnabledColors = css<ValidationProps>`
  ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.textDisabled
      : p.theme.midwestColors.componentLinkDefault};
`;

const disabledEnabledHoverColors = css<ValidationProps>`
  ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.textDisabled
      : p.theme.midwestColors.componentLinkHover};
`;

const visitedEnabledDisabledColors = css<ValidationProps>`
  ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.textDisabled
      : p.theme.midwestColors.componentLinkVisited};
`;

const hoverFocusIconColors = css<ValidationProps>`
  fill: ${disabledEnabledColors};
  width: ${(p) => (p.$size === 'large' ? 16 : 12)}px;
  height: ${(p) => (p.$size === 'large' ? 16 : 12)}px;
  margin-left: ${(p) => p.theme.spacing.xxxsm};
  margin-top: ${(p) => (p.$size === 'large' ? -2 : 0)}px;

  &:hover {
    fill: ${disabledEnabledHoverColors};
  }
`;

export const ChevronRightIcon = styled((props) => <ArrowRight {...props} />)`
  ${hoverFocusIconColors};
`;

export const LinkOutIcon = styled((props) => <ArrowDiagonal {...props} />)`
  ${hoverFocusIconColors};
`;

export const BaseLinkStyles = css<BaseProps>`
  &:hover,
  &:focus {
    ${ChevronRightIcon}, ${LinkOutIcon} {
      fill: ${disabledEnabledHoverColors};
    }
  }
`;

export const LinkStyles = css<BaseProps>`
  text-decoration: none;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  align-items: center;

  color: ${disabledEnabledColors};
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${disabledEnabledHoverColors};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 ${(p) => p.theme.border.widthFocused}
      ${(p) =>
        p.$darkBg
          ? p.theme.midwestColors.borderFocusDarkBg
          : p.theme.midwestColors.borderFocus};
    border-radius: 5px;
  }

  &:active {
    color: ${(p) => p.theme.midwestColors.componentLinkDefault};
  }

  &:visited {
    color: ${visitedEnabledDisabledColors};

    ${ChevronRightIcon} {
      fill: ${visitedEnabledDisabledColors};
    }

    ${LinkOutIcon} {
      fill: ${visitedEnabledDisabledColors};
    }
  }
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`;

export const LinkWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none;
  color: inherit;
`;
