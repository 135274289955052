import { getLicensePlate } from './licensePlate';
import { useEffect, useState } from 'react';

export const useLicensePlate = () => {
  const [licensePlate, setLicensePlate] = useState<string | null>(null);

  useEffect(() => {
    setLicensePlate(getLicensePlate());
  }, []);

  return licensePlate;
};
