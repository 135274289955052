import { ButtonVariationProps } from '../types';
import { getBackgroundColor } from './backgroundColors';
import {
  MidwestColors,
  textInterfaceSemiDefault,
  textInterfaceSemiSm,
} from '@thrivent/midwest-shared';
import { css } from 'styled-components';

export const primaryStrongStyles = css<ButtonVariationProps>`
  background: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors).primaryStrongBg};
  color: ${(p) => p.theme.midwestColors.componentButtonColorTextStrongPrimary};
  font-weight: ${textInterfaceSemiDefault.weight};
  line-height: ${textInterfaceSemiDefault.lineHeight}px;
  font-size: ${textInterfaceSemiDefault.rem}rem;
  ${(p) =>
    p.size === 'small' &&
    css`
      line-height: ${textInterfaceSemiSm.lineHeight}px;
      font-size: ${textInterfaceSemiSm.rem}rem;
      font-weight: ${textInterfaceSemiSm.weight};
    `};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongPrimaryHover};
      }
      &:hover {
        background: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongPrimaryHover};
      }
      &:active {
        background: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongPrimaryPressed};
      }
    `}
`;

export const primarySubtleStyles = css<ButtonVariationProps>`
  background: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors).primarySubtleBg};
  outline: ${(p) => p.theme.border.widthDefault} solid
    ${(p) =>
      p.theme.midwestColors.componentButtonColorBorderSubtlePrimaryDefault};
  color: ${(p) => p.theme.midwestColors.componentButtonColorTextSubtlePrimary};
  ${(p) =>
    !p.disabled &&
    css`
      &:focus-visible {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtlePrimaryHover};
      }
      &:hover {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtlePrimaryHover};
        outline-color: ${(p) =>
          p.theme.midwestColors.componentButtonColorBorderSubtlePrimaryHover};
      }
      &:active {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtlePrimaryPressed};
        outline-color: ${(p) =>
          p.theme.midwestColors.componentButtonColorBorderSubtlePrimaryPressed};
      }
    `}
`;

export const primarySecondaryGhostStyles = css<ButtonVariationProps>`
  background: ${({ theme }) =>
    getBackgroundColor(theme.midwestColors as MidwestColors).primaryGhostBg};
  outline: ${(p) => p.theme.border.widthDefault} solid transparent;
  color: ${(p) => p.theme.midwestColors.componentButtonColorTextGhostPrimary};
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtlePrimaryHover};
        outline-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundStrongPrimaryHover};
      }
      &:active {
        background-color: ${(p) =>
          p.theme.midwestColors
            .componentButtonColorBackgroundSubtlePrimaryPressed};
        outline-color: ${(p) =>
          p.theme.midwestColors.componentButtonColorBorderSubtlePrimaryPressed};
      }
    `}
`;
